const React = require('react')
const { useEffect } = require("react");
const backgroundImage = require('./background.png')
const appleImage = require('./apple.png')
const androidImage = require('./android.png')
const bittarLogo = require('./logo.png')

const DownloadScreen = () => {
  // Função para detectar o sistema operacional do dispositivo
  function detectDeviceOS() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  // Função para redirecionar o usuário para a loja de aplicativos correta
  function redirectToAppStore() {
    var deviceOS = detectDeviceOS();

    if (deviceOS === "Android") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.bittareducacao.enem";
    } else if (deviceOS === "iOS") {
      window.location.href =
        "https://apps.apple.com/us/app/bittar-enem/id1569198304";
    } else {
      console.log("Desculpe, seu dispositivo não é suportado.");
    }
  }

  useEffect(() => {
    redirectToAppStore();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "repeat",
        backgroundSize: "contain",
        height: "100vh",
        width: "100%",
        backgroundColor: 'black',
      }}
    >
      <img
        src={bittarLogo}
        alt="Bittar Enem"
        style={{
          width: "100%",
          maxWidth: "180px",
          margin: "0 auto",
          marginTop: "30px",
        }}
      />

      <div
        style={{ marginTop: "30px", display: "flex", flexDirection: "column" }}
      >
        <p style={{ color: "#fff" }}>
          Faça o download agora mesmo do aplicativo:
        </p>
        <a
          href="https://play.google.com/store/apps/details?id=com.bittareducacao.enem"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src={androidImage}
            alt="Baixar na Play Store"
            title="Baixar na Play Store"
            style={{
              width: "100%",
              maxWidth: "200px",
              margin: "0 auto",
              marginTop: "10px",
              cursor: "pointer",
            }}
          />
        </a>
        <a
          href="https://apps.apple.com/us/app/bittar-enem/id1569198304"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src={appleImage}
            alt="Baixar na App Store"
            title="Baixar na App Store"
            style={{
              width: "100%",
              maxWidth: "200px",
              margin: "0 auto",
              marginTop: "15px",
              cursor: "pointer",
            }}
          />
        </a>
      </div>
    </div>
  );
};

export default DownloadScreen;
