import React from 'react';
import { Grid } from '@material-ui/core';

import Theme from 'style/Theme';

import LogoImage from 'assets/images/logo.png';

const Header = () => {
  return (
    <Grid container justify="center" alignItems="center" style={{ padding: 0, width: '100%', backgroundColor: Theme.secondary, padding: 30 }}>
      <img src={LogoImage} height={45} alt="Logo Bittar Enem" />
    </Grid>
  )
}

export default Header;
