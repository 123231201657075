import { create } from 'apisauce';

const API_URL = 'https://api-producao.bittareducacao.com.br'; 

const API = create({
  baseURL: `${API_URL}/api`,
  headers: {
    "content-type": "application/json"
  },
  timeout: 30000,
 });

export default API;
