import React from 'react';
import { Grid, Hidden } from '@material-ui/core';

import Text from 'components/Text';

import FirstIcon from 'assets/images/icons/1.png';
import SecondIcon from 'assets/images/icons/2.png';
import ThirdIcon from 'assets/images/icons/3.png';

import * as Styled from './styles';
import Theme from 'style/Theme';

const ICONS = [
  { title: 'PROFESSORES QUE DOMINAM O ENEM', icon: FirstIcon },
  { title: 'SIMULADOS PERSONALIZADOS', icon: SecondIcon },
  { title: 'AULAS PREMIUM', icon: ThirdIcon }
];

const Cards = () => (
  <Styled.Container container justify="center">
    {ICONS.map((item, index) => (
      <Styled.Content item key={`${index}`} xs={12} md={3}>
        <Styled.Card container justify="center" alignItems="center">            
          <Grid item container justify="center" xs={12} md={4}>
            <img src={item.icon} alt={item.title} height={80} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Text color={Theme.primary} bold align="center">{item.title}</Text>
          </Grid>
        </Styled.Card>
      </Styled.Content>
    ))}
  </Styled.Container>
);

export default Cards;
