import Styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Card = Styled(Grid)`
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 1px 1px 9px -3px rgba(0,0,0,0.71);
  max-width: 350px;
  padding: 20px;
  font-size: 18px;

  @media (max-width: 959px) {
    max-width: initial;
    font-size: 16px !important;
  }
`;

export const Container = Styled(Grid)`
  margin-top: -50px;
  position: relative;

  @media (max-width: 767px) {
    background-color: ${({ theme }) => theme.primary};
    padding-top: 20px;
    padding-bottom: 40px;
    position: initial;
  }
`;

export const Content = Styled(Grid)`
  height: 100%;
  max-width: 400px;
  padding: 15px;
`;
