import React from "react";
import { Routes, Route } from "react-router-dom";
import { Grid } from "@material-ui/core";

import Home from "containers/Home";

import Theme from "style/Theme";
import DownloadScreen from "containers/Download";
import TermsScreen from "containers/Terms";

export default function MainRoutes() {
  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      style={{ flex: 1, backgroundColor: "#111" }}
    >
      <div
        style={{ flex: 1, width: "100%", backgroundColor: Theme.background }}
      >
        <Routes>
          <Route path="/download" element={<DownloadScreen />} />
          <Route path="/termos-de-uso" element={<TermsScreen />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </div>
    </Grid>
  );
}
