import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { useStoreActions } from 'easy-peasy';

import ErrorText from 'components/ErrorText';
import Text from 'components/Text';

import IosImage from 'assets/images/app.png';
import AndroidImage from 'assets/images/google.png';

import ArrowBlackRightImage from 'assets/images/arrow-black-right.png';

import * as Styled from './styles';
import Theme from 'style/Theme';

const formSchema = Yup.object().shape({
  name: Yup
    .string('Informe seu nome')
    .required('Informe seu nome'),
  email: Yup
    .string('Informe seu e-mail')
    .email('Digite um e-mail válido')
    .required('Informe seu e-mail')
});

const initialValues = {
  name: '',
  email: ''
};

const Form = () => {
  const send = useStoreActions(({ contact }) => contact.send);

  const android_url = 'https://play.google.com/store/apps/details?id=com.bittareducacao.enem';
  const ios_url = 'https://apps.apple.com/app/id1569198304';

  const onSubmit = ({ name = '', email = '' }) => {
    if (!name || !email) return;
    send({ name, email });
  };

  return (
    <Styled.Container container alignItems="flex-end">
      <Styled.Content container jusitfy="center" alignItems="center">
        <Grid item xs={12} md={6} >
          <Styled.Title size={36} color={Theme.primary} bold align="center">
            APRENDA GRATUITAMENTE
            <Styled.TitleBig size={48} color={Theme.primary} bold>
              COM PROFESSORES
            </Styled.TitleBig>
            ESPECIALISTAS NO ENEM
          </Styled.Title>
          <Styled.DownloadText size={22} color={Theme.secondary} bold align="center" style={{ marginTop: 15 }}>
            BAIXE O APLICATIVO BITTAR ENEM AGORA!
          </Styled.DownloadText>

          <Hidden smDown>
            <Grid container justify="flex-end">
              <img src={ArrowBlackRightImage} style={{ position: 'relative', right: 25, marginTop: 20 }} />
            </Grid>
          </Hidden>
        </Grid>

        <Styled.FormContainer xs={12} md={6} item container direction="column" jusitfy="center" alignItems="center">
          <Text size={22} color={Theme.secondary} bold align="center">
            PREENCHA O FORMULÁRIO E FAÇA O DOWNLOAD GRATUITO DO APLICATIVO BITTAR ENEM
          </Text>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={formSchema}
          >
            {({ errors, touched, handleSubmit }) => (
              <FormikForm style={{ width: '100%' }}>
                <Grid
                  item container
                  direction="column"
                  style={{ marginTop: 20 }}
                  id="form"
                >
                  <Grid item>
                    <Styled.Label>
                      Nome
                    </Styled.Label>
                    <Styled.Input
                      name="name"
                      label="Nome"
                      placeholder="Digite seu nome"
                    />
                    {errors.name && touched.name ? <ErrorText text={errors.name} /> : null}
                  </Grid>

                  <Grid item style={{ marginTop: 10 }}>
                    <Styled.Label>
                      E-mail
                    </Styled.Label>
                    <Styled.Input
                      type="email"
                      name="email"
                      label="E-mail"
                      placeholder="Digite seu e-mail"
                      variant="filled"
                    />
                    {errors.email && touched.email ? <ErrorText text={errors.email} /> : null}
                  </Grid>

                  <Grid container justify="center" style={{ marginTop: 15 }}>
                    <Grid container justify="center" item xs={12} md={6} style={{ padding: 5 }}>
                      {/* <Button background={Theme.tertiary} onClick={() => { handleSubmit(); window.open(android_url, '_blank'); }}>
                        <Text color={Theme.white.primary} size={28} bold>PLAYSTORE</Text>
                      </Button> */}
                      <img
                        src={AndroidImage}
                        style={{ width: '90%', maxWidth: 320, cursor: 'pointer' }}
                        title="Baixar para Android"
                        alt="Android"
                        onClick={() => { handleSubmit(); window.open(android_url, '_blank'); }}
                      />
                    </Grid>

                    <Grid container justify="center" item xs={12} md={6} style={{ padding: 5 }}>
                      {/* <Button background={Theme.tertiary} onClick={() => { handleSubmit(); window.open(ios_url, '_blank'); }}>
                        <Text color={Theme.white.primary} size={28} bold>APPLESTORE</Text>
                      </Button>
                        */}
                      <img
                        src={IosImage}
                        style={{ width: '90%', maxWidth: 320, cursor: 'pointer' }}
                        title="Baixar para Ios"
                        alt="Ios"
                        onClick={() => { handleSubmit(); window.open(ios_url, '_blank'); }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </FormikForm>
            )}
          </Formik>

        </Styled.FormContainer>
      </Styled.Content>
    </Styled.Container>
  );
};

export default Form;
