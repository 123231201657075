import { action, thunk } from 'easy-peasy';
import { toast } from 'react-toastify';

import { create } from 'apisauce';
import Contact from 'api/contact';

const ContactAPI = create({
  baseURL: 'https://blb49gknv3.execute-api.us-west-2.amazonaws.com',
  headers: {
    'content-type': 'application/json',
    'api-token': 'BcEbe-1cDd1-3Fc62-6C65a-78f28-3Y195',
  },
  timeout: 30000,
});

export default {
  loading: false,

  setData: action((state, { type, payload }) => {
    state[type] = payload;
  }),

  send: thunk(async (actions, payload) => {
    actions.setData({ type: 'loading', payload: true });
    
    const send = await Contact.insertContact(payload);

    const response = await ContactAPI.post('/sbe-contact-form', {
      ...payload,
      "origin": "ENEM",
    });
    if (!response?.ok) {
      let message = response?.data?.msg || 'Ocorre um erro inesperado, verifique sua conexão e tente novamente.'
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    actions.setData({ type: 'loading', payload: false });

    return response?.ok;
  }),
};
