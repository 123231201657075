import Styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Text from 'components/Text';

export const ButtonContainer = Styled(Grid)`
  margin-top: 20px;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const SecondButtonContainer = Styled(Grid)`
  margin-bottom: 20px;
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`;

export const ImgEffect = Styled.img`
  height: 100%;
  max-height: 550px;
  position: absolute;
`;

export const Title = Styled(Text)`
  font-size: 22px;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;
