export const Theme = {
  background: '#f9f9f9',
  primary: '#2373be',
  secondary: '#142d5f',
  tertiary: '#00b9f1',
  black: {
    primary: '#060606',
    secondary: '#191C21',
  },
  gray: {
    primary: '#91A3AD',
    secondary: '#E5F5EC',
    tertiary: '#DFDFDF',
  },
  green: {
    primary: '#07B036',
    secondary: '#5CE157',
    tertiary: '#44c63f',
  },
  red: {
    primary: '#C0392B',
  },
  white: {
    primary: '#FFFFFF',
  },
};

export default Theme;
