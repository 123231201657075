import React from "react";
import { Grid } from "@material-ui/core";

// import FbIcon from 'assets/images/social/1.png';
// import InstaIcon from 'assets/images/social/2.png';
// import KwaiIcon from 'assets/images/social/3.png';
// import TikTokIcon from 'assets/images/social/4.png';
// import WhatsIcon from 'assets/images/social/5.png';
// import YTIcon from 'assets/images/social/6.png';

import LogoIcon from "assets/images/logo.png";

import Theme from "style/Theme";

const Footer = () => (
  <Grid
    container
    alignItems="center"
    justifyContent="space-between"
    style={{ backgroundColor: Theme.secondary, padding: 20 }}
  >
    <a
      href="mailto:contato@bittarenem.com.br"
      style={{ color: "#fafafa", textDecoration: "none" }}
    >
      contato@bittarenem.com.br
    </a>
    {/* <img src={FbIcon} alt="Fcebook" height={20} />
    <img src={InstaIcon} alt="Instagram" height={20} style={{ marginLeft: 5 }} />
    <img src={TikTokIcon} alt="TikTok" height={20} style={{ marginLeft: 5 }} />
    <img src={YTIcon} alt="Youtube" height={20} style={{ marginLeft: 5 }} />
    <img src={KwaiIcon} alt="Kwai" height={20} style={{ marginLeft: 5 }} />
    <img src={WhatsIcon} alt="Whatsapp" height={20} style={{ marginLeft: 5 }} />
    <Grid item style={{ height: 30, width: 2, backgroundColor: '#fafafa', margin: '0 10px' }}></Grid> */}
    <img src={LogoIcon} alt="Logo Bittar Enem" height={20} />
  </Grid>
);

export default Footer;
