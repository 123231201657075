import React from 'react';
import { Grid } from '@material-ui/core';

import Button from 'components/Button';
import Text from 'components/Text';
import Theme from 'style/Theme';

import EffectLeftImage from 'assets/images/effect-left.png';
import EffectRightImage from 'assets/images/effect-right.png';
import ArrowLeftImage from 'assets/images/arrow-left.png';
import ArrowRightImage from 'assets/images/arrow-right.png';

import AprrovedImage from 'assets/images/aprroved.png';
import HeaderImage from 'assets/images/header.png';

import * as Styled from './styles';

const Overview = () => (
  <Grid container justify="center" alignItems="center" style={{ backgroundColor: Theme.primary, padding: '40px 30px' }}>
    <Styled.ImgEffect src={EffectRightImage} style={{ left: 0 }} alt="Background effect left" />

    <Grid item xs={12} md={8} container direction="column" justify="center" alignItems="center">
      <Styled.Title color="#fff" size={22} align="center">
        Está chegando a sua vez de comemorar a
      </Styled.Title>

      <img src={AprrovedImage} alt="aprovação" style={{ width: '100%', maxWidth: 450 }} />

      <Styled.ButtonContainer container justify="center" alignItems="center">
        <img src={ArrowRightImage} style={{ marginRight: 10, width: '15%' }} alt="Arrow right" />
        <Button background={Theme.tertiary} href="#form">
          <Text color={Theme.white.primary} size={28} bold>COMEÇAR OS ESTUDOS</Text>
        </Button>
        <img src={ArrowLeftImage} style={{ marginLeft: 10, width: '15%' }} alt="Arrow left" />
      </Styled.ButtonContainer>
    </Grid>

    <Grid item container xs={12} md={4} direction="column" justify="center" alignItems="center">
      <img src={HeaderImage} alt="100% Gratuito" style={{ width: '100%', marginTop: 20, marginBottom: 20 }} />

      <Styled.SecondButtonContainer container justify="center" alignItems="center">
        <img src={ArrowRightImage} alt="Arrow right" style={{ width: '20%' }} />
        <Button background={Theme.tertiary} href="#form" style={{ width: '55%', height: '100%', marginTop: -40, marginLeft: '2%', marginRight: '2%' }}>
          <Text color={Theme.white.primary} size={14} bold>COMEÇAR OS ESTUDOS</Text>
        </Button>
        <img src={ArrowLeftImage} alt="Arrow left" style={{ width: '20%' }} />
      </Styled.SecondButtonContainer>
    </Grid>

    <Styled.ImgEffect src={EffectLeftImage} style={{ right: 0 }} alt="Background effect right" />
  </Grid>
);

export default Overview;
