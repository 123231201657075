import Styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import Text from 'components/Text';

export const Container = Styled(Grid)`
  background-color: #fff;
  margin-top: -30px;
  padding: 0 30px;
  width: 100%;

  @media (max-width: 767px) {
    margin-top: 20px;
  }
`;

export const Content = Styled(Grid)`
  background-color: #f6f6f6;
  padding: 50px;

  @media (max-width: 767px) {
    padding: 40px 20px;
  }
`;

export const FormContainer = Styled(Grid)`
  background-color: #fff;
  box-shadow: 1px 1px 9px -3px rgba(0,0,0,0.71);
  margin-top: 20px;
  padding: 30px;

  @media (max-width: 767px) {
    margin-top: 35px;
  }
`;

export const Input = Styled(Field)`
  flex: 1;
  font-size: 18px;
  width: 93.5%;
  border-radius: 5px;
  border: 2px solid #eaeaea;
  margin-top: -8px;
  padding: 10px 15px;

  @media (max-width: 767px) {
    width: 90%;
    height: 35px;
  }
`;

export const Label = Styled.span`
  background-color: #fff;
  font-size: 15px;
  margin-left: 10px;
  padding: 2px 7px;
  position: relative;
  z-index: 999;
`;

export const Title = Styled(Text)`
  font-size: 34px;

  @media (max-width: 767px) {
    font-size: 28px;
  }
`;

export const TitleBig = Styled(Text)`
  font-size: 48px;

  @media (max-width: 767px) {
    font-size: 39px;
  }
`;

export const DownloadText = Styled(Text)`
  font-size: 22px;

  @media (max-width: 767px) {
    font-size: 18px;
  }
`;
