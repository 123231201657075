import React from 'react';
import { Grid } from '@material-ui/core';

import Cards from './components/Cards';
import Footer from './components/Footer';
import Form from './components/Form';
import Header from './components/Header';
import Overview from './components/Overview';

export default function Home() {
  return (
    <Grid style={{ padding: 0, width: '100%', backgroundColor: '#fff' }}>
      <Header />

      <Overview />

      <Cards />

      <Form />

      <Footer />
    </Grid>
  );
};
